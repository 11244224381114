import MessageBuilder from "@/services/messages";
import DmarcInterpreter from "@/services/analysis/dmarc";
import SpfInterpreter from "@/services/analysis/spf";
import ScoreInterpreter from "@/services/analysis/score";

export default {
    setScore(state, {value}) {
        state.score = value;
    },

    setAnalysis(state, {value}) {
        state.analysis = ScoreInterpreter.explain(value);
    },

    setAnalysisStatus(state, {value}) {
        state.status = value;
    },

    setSpfMessages(state, {value}) {
        state.spfMessages = MessageBuilder
            .useData(value)
            .withInterpreter(SpfInterpreter)
            .build();
    },

    setDmarcMessages(state, {value}) {
        state.dmarcMessages = MessageBuilder
            .useData(value)
            .withInterpreter(DmarcInterpreter)
            .build();
    },

    setLoading(state, {value}) {
        state.loading = value;
    },

    setDomain(state, {value}) {
        state.domain = value;
    },

    setHasAnalysisRun(state, {value}) {
        state.hasAnalysisRun = value;
    }
}