import request from 'axios';

const DOMAIN_ANALYSIS_API = 'https://tools.sendmarc.com/api/domain-analysis/analysis';
const UPDATE_SCORE = 'setScore';
const UPDATE_LOADING = 'setLoading';
const UPDATE_DOMAIN = 'setDomain';
const UPDATE_SPF_MESSAGES = 'setSpfMessages';
const UPDATE_DMARC_MESSAGES = 'setDmarcMessages';
const UPDATE_ANALYSIS_STATUS = 'setAnalysisStatus';
const UPDATE_ANALYSIS = 'setAnalysis';
const UPDATE_HAS_ANALYSIS_RUN = 'setHasAnalysisRun';

export default {
    getAnalysis({commit}, {domain, email, token}) {
        commit({type: UPDATE_LOADING, value: true});
        commit({type: UPDATE_DOMAIN, value: domain});

        request.post(DOMAIN_ANALYSIS_API, {email, token})
               .then(({data}) => {
                    commit({type: UPDATE_ANALYSIS_STATUS, value: true});
                    commit({type: UPDATE_SCORE, value: data.domainScore});
                    commit({type: UPDATE_ANALYSIS, value: data.domainScore});
                    commit({type: UPDATE_SPF_MESSAGES, value: data.spfAnalysis});
                    commit({type: UPDATE_DMARC_MESSAGES, value: data.dmarcAnalysis});})
               .catch(error => {
                    if (error.response.status === 400) {
                        commit({type: UPDATE_ANALYSIS_STATUS, value: false});
                    }})
               .finally(() => {
                    commit({type: UPDATE_LOADING, value: false});
                    commit({type: UPDATE_HAS_ANALYSIS_RUN, value: true});});
    }
};