<script>
    import DomainAnalysisInputForm from "@/components/InputForm";
    import DomainAnalysisChart from "@/components/DomainScore";
    import DomainAnalysisDmarcMessages from "@/components/DmarcMessages";
    import DomainAnalysisSpfMessages from "@/components/SpfMessages";

    export default {
        name: "Dashboard",

        template: "#dashboard-template",

        components: {DomainAnalysisInputForm, DomainAnalysisChart, DomainAnalysisDmarcMessages, DomainAnalysisSpfMessages},

        methods: {
            reset() {
                this.$store.reset();
            }
        }
    }
</script>
