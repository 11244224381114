import state from "@/store/modules/domain-analysis/state";
import actions from "@/store/modules/domain-analysis/actions";
import mutations from "@/store/modules/domain-analysis/mutations";
import getters from "@/store/modules/domain-analysis/getters";

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
};