const state = function () {
    return {
        domain:null,
        score:0,
        analysis: null,
        status: false,
        hasAnalysisRun: false,
        spfMessages: null,
        dmarcMessages: null,
        loading: false,
        errorMessage: 'Could not analyze the specified domain.'
    }
}

export default state;