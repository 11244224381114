export default {
    analysis: {
        "dns>10": "Your authorised sender record has too many DNS lookups making it invalid, leaving your domain open to abuse",
        "dns>6": "Your authorised sender record is close to the DNS lookup limits, we can help reduce these lookups",
        "void>2": "Your authorised sender record is close to the failing lookup limits, we can help reduce these lookups",
        "void>0": "Your authorised sender record is close to the failing lookup limits, we can help reduce these lookups",
        "recursive": "Your authorised sender record contains recursive lookups making it invalid, leaving your domain open to abuse",
        "errors": "Your authorised sender record contains errors and may not be listing all authorised senders",
        "no all": "Your authorised sender record does not specify how to deal with servers not listed and will leave your domain open to abuse",
        "~all": "Unauthorised senders will only be flagged as suspicious and not rejected",
        "?all": "Unauthorised senders may be allowed to send from your domain",
        "+all": "Unauthorised senders are allowed to send from your domain",
        "mxa>10": "Your authorised sender record has too many MX resource records making it invalid, leaving your domain open to abuse",
        "No record": "Your domain does not list any authorised sending servers leaving your domain open to abuse",
        "Invalid record": "Your domain does not list any authorised sending servers leaving your domain open to abuse",
    },

    explain(code) {
        return this.analysis[code];
    }
};