import Vue from 'vue';
import Vuex from 'vuex';
import {createStore} from 'vuex-extensions';
import DomainAnalysis from '@/store/modules/domain-analysis';

Vue.use(Vuex);

const store = createStore(Vuex.Store, {strict: process.env.NODE_ENV !== 'production'});

store.registerModule('domain-analysis', DomainAnalysis);

export default store;

