export default {
    analysis: {
        "p=none": "We could not find a policy record",
        "No record": "We could not find a policy record",
        "p=quarantine": "Suspicious messages will be quarantined",
        "sp=none": "No action will be taken with suspicious messages from any sub-domains",
        "sp=quarantine": "Suspicious messages from any sub-domains will be quarantined",
        "pct=0": "Your policy is not being applied to any emails and you may not receive feedback on all volume",
        "pct<100": "Your policy is only applied to a percentage of emails and you may not receive feedback on all volume",
        "rua=[]": "You do not have full visibility on email sent from your domain making it harder to configure",
        "ruf=[]": "You are not receiving feedback on suspicious emails making it harder to find senders abusing your domain"
    },

    explain(code) {
        return this.analysis[code];
    }
};