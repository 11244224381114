<script>
    import Chart from 'chart.js';
    import {
        CHART_COLOR_GREEN,
        CHART_COLOR_ORANGE,
        CHART_COLOR_RED,
        CHART_COLOR_UNSCORED,
        CHART_RESPONSIVENESS,
        CHART_SHOW_TITLE,
        CHART_THICKNESS,
        CHART_TITLE
    } from "@/services/chart/style";

    export default {
        name: "DomainScore",

        template: "#chart-template",

        mounted() {
            this.createChart();
        },

        computed: {
            chartData() {
                return [this.score, this.maxScore - this.score]
            },

            chartCutOutPercentage() {
                return 100 - CHART_THICKNESS;
            },

            chartTitle() {
                return CHART_TITLE.length > 0
                    ? CHART_TITLE
                    : "Domain Score: ".concat(`${this.score}/${this.maxScore}`);
            },

            scoreColor() {
                if(this.score >= 4) {
                    return CHART_COLOR_GREEN;
                }

                if(this.score >= 2) {
                    return CHART_COLOR_ORANGE;
                }

                return CHART_COLOR_RED;
            }
        },

        data() {
            return {
                chartId: "analysis-chart",
                maxScore: 5
            };
        },

        methods: {
            createChart() {
                const ctx = document.getElementById(this.chartId);
                new Chart(ctx, {
                    type: 'doughnut',
                    data: {
                        datasets: [{
                            data: this.chartData,
                            backgroundColor: [this.scoreColor, CHART_COLOR_UNSCORED]
                        }]
                    },
                    options:{
                        responsive: CHART_RESPONSIVENESS,
                        cutoutPercentage: this.chartCutOutPercentage,
                        events: [],
                        title: {
                            display: CHART_SHOW_TITLE,
                            text: this.chartTitle
                        },
                        legend: {
                            display: false
                        },
                        tooltips: {
                            callbacks: {
                                label: function(tooltipItem) {
                                    return tooltipItem.yLabel;
                                }
                            }
                        }
                    }
                });

            }
        }

    }
</script>