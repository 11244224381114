import Vue from 'vue';
import DomainAnalysisDashboard from "@/Dashboard";
import store from '@/store';
import mixins from '@/mixins';

Vue.config.productionTip = false;

Vue.mixin(mixins);

// eslint-disable-next-line no-undef
Vue.prototype.$sendmarcToken = SENDMARC_TOKEN;

new Vue({
  render: h => h(DomainAnalysisDashboard),
  store,
}).$mount('#app');
