export default {
    data: null,

    interpreter: null,

    useData(data) {
        this.data = data;
        return this;
    },

    withInterpreter(interpreter) {
        this.interpreter = interpreter;
        return this;
    },

    formatMessage(messageType) {
        return {
            type: messageType,
            text: this.data[messageType].map(k => this.interpreter.explain(k))
        };
    },

    getDefaultMessages () {
        return [{
            type: "info",
            text: ["This policy is configured correctly"]
        }];
    },

    getFormattedMessages() {
        return Object
            .keys(this.data)
            .map(type => this.formatMessage(type));
    },

    assertDataIsNotObject() {
        return Array.isArray(this.data) || !this.data;
    },

    build() {
        return this.assertDataIsNotObject()
            ? this.getDefaultMessages()
            : this.getFormattedMessages()
    }
};