<script>
    import {EMAIL_VALIDATOR, matchFirstDNS} from "../services/validation/dns";

    export default {
        name: "InputForm",

        template: "#input-form-template",

        data() {
            return {
                email: null,
                isValid: true,
            };
        },

        methods: {
            runAnalysis() {
                this.isValid = true;

                if(! EMAIL_VALIDATOR.test(this.email)) {
                    this.isValid = false;

                    return;
                }

                this.$store.reset();
                this.dispatchAnalysis({domain: matchFirstDNS(this.email), email:this.email, token: this.$sendmarcToken});
            }
        }

    }
</script>