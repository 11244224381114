export default {
    analysis:  {
        0: "Domain is not protected",
        1: "Domain is not fully protected from phishing and spoofing attacks.",
        2: "Domain is not fully protected from phishing and spoofing attacks.",
        3: "Domain is not fully protected from phishing and spoofing attacks.",
        4: "Domain is not fully protected from phishing and spoofing attacks.",
        5: "Domain is fully compliant with DMARC standards and  fully protected from phishing and spoofing attacks."
    },

    explain(code) {
        return this.analysis[code];
    }
};