import {mapActions, mapGetters} from "vuex";

export default {
    computed: {
        ...mapGetters({
            "score": "domain-analysis/getScore",
            "currentDomain": "domain-analysis/getDomain",
            "analysis": "domain-analysis/getAnalysis",
            "analysisCompleted": "domain-analysis/getAnalysisStatus",
            "spfMessages": "domain-analysis/getSpfMessages",
            "dmarcMessages": "domain-analysis/getDmarcMessages",
            "loading": "domain-analysis/getLoading",
            "errorMessage": "domain-analysis/getErrorMessage",
            "hasAnalysisRun": "domain-analysis/getHasAnalysisRun",

        })
    },

    methods: {
        ...mapActions({
            "dispatchAnalysis": "domain-analysis/getAnalysis"
        })
    }
}