export default {
    getScore(state) {
        return state.score;
    },

    getDomain(state) {
        return state.domain;
    },

    getSpfMessages(state) {
        return state.spfMessages;
    },

    getDmarcMessages(state) {
        return state.dmarcMessages;
    },

    getLoading(state) {
        return state.loading;
    },

    getAnalysis(state) {
        return state.analysis;
    },

    getAnalysisStatus(state) {
        return state.status;
    },

    getErrorMessage(state) {
        return state.errorMessage;
    },

    getHasAnalysisRun(state) {
        return state.hasAnalysisRun;
    }
};